const UserState = {
  user_id: 0,
  email: "",
  email_verification: false,
  name: "",
  admin: false,
  title: "",
  photo: "",
  langauge: "",
  department: "",
  mobile: "",
  token: "",
};

export default UserState;
