import * as api from "../../apis/alert.api";
import * as actionTypes from "./alert.actions";

export const SetAlertsCall = (action) =>
  api.AddAlert(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ALERT,
        alerts: data,
      });
    }
  });

export const GetAlertsCall = (action) =>
  api.getAlert(action.user_id).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ALERT,
        alerts: data,
      });
    }
  });

export const UpdateAlertsCall = (action) =>
  api.updateAlert(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ALERT,
        alerts: data,
      });
    }
  });

export const DeleteAlertsCall = (action) =>
  api.removeAlert(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ALERT,
        alerts: data,
      });
    }
  });
