import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Routes from "./routes";
import LoadingIndicator from "./components/LoadingIndicator/index";

const Login = lazy(() => import("./Pages/Login/index"));
const Signup = lazy(() => import("./Pages/Signup/index"));
const Bot = lazy(() => import("./Pages/Bot/index"));
const Home = lazy(() => import("./Pages/Home/index"));
const ForgotPassword = lazy(() => import("./Pages/ForgotPassword/index"));
const Admin = lazy(() => import("./Pages/Admin/index"));
const Alert = lazy(() => import("./Pages/Alerts/index"));
const Profile = lazy(() => import("./Pages/Profile/index"));
const ActivityLog = lazy(() => import("./Pages/ActivityLog/index"));
const ResetPassword = lazy(() => import("./Pages/ResetPassword/index"));
const KPI = lazy(() => import("./Pages/KPI/index"));
const Skills = lazy(() => import("./Pages/Skills/index"));

function App() {
  return (
    <div className="App">
      <Suspense fallback={LoadingIndicator}>
        <Router>
          <Switch>
          <Route path={Routes.login} component={Login}></Route>
          <Route path={Routes.kpi} component={KPI}></Route>
          <Route path={Routes.skills} component={Skills}></Route>
            <Route path={Routes.login} component={Login}></Route>
            <Route path={Routes.signup} component={Signup}></Route>
            <Route path={Routes.bot} component={Bot}></Route>
            <Route path={Routes.forgot} component={ForgotPassword}></Route>
            <Route path={Routes.admin} component={Admin}></Route>
            <Route path={Routes.alerts} component={Alert}></Route>
            <Route path={Routes.profile} component={Profile}></Route>
            <Route path={Routes.activityLog} component={ActivityLog}></Route>
            <Route path={Routes.resetPassword} component={ResetPassword}></Route>
            <Route path={Routes.home} component={Home}></Route>
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;
