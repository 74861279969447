import { serverURL } from "../constants";
import {
  createPostJSON,
  createGetJSON,
  createDELETEJSON,
} from "../helper/customs";

const getUserRecents = (user_id) => {
  return fetch(serverURL.concat(`/recent?user_id=${user_id}`), createGetJSON())
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const addUserRecents = (data) => {
  return fetch(serverURL.concat(`/recent`), createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const getUserFavorite = (user_id) => {
  return fetch(
    serverURL.concat(`/favorite?user_id=${user_id}`),
    createGetJSON()
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const addUserFavorite = (data) => {
  return fetch(serverURL.concat(`/favorite`), createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const removeUserFavorite = (data) => {
  return fetch(serverURL.concat(`/favorite`), createDELETEJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const removeUserRecent = (data) => {
  return fetch(serverURL.concat(`/recent`), createDELETEJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export {
  getUserRecents,
  getUserFavorite,
  addUserFavorite,
  addUserRecents,
  removeUserFavorite,
  removeUserRecent,
};
