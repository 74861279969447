import * as api from "../../apis/company.api";
import * as actionTypes from "./company.actions";

export const UpdateInfoCall = (action) =>
  api.updateInfo(action.companyInfo).then((data) => {
    if (data.message) {
    } else {
      console.log("company  info", data)
      action.asyncDispatch({
        type: actionTypes.SET_INFO,
        company: data,
      });
    }
  });

export const UpdateConnectionCall = (action) =>
  api.updateConnection(action.companyInfo).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_INFO,
        company: data,
      });
    }
  });

export const GetCompanyUsersCall = (action) =>
  api.getCompanyUsers(action.companyInfo.company_id).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_USER_INFO,
        company_users: data,
      });
    }
  });

export const SetCompanyUsersCall = (action) =>
  api
    .setCompanyUsers({
      user_id: action.user_id,
      company_id: action.company_id,
      company_user: action.Info,
    })
    .then((data) => {
      if (data.message) {
      } else {
        action.asyncDispatch({
          type: actionTypes.SET_USER_INFO,
          company_users: data,
        });
      }
    });

export const UpdateCompanyUsersCall = (action) => {
  console.log("CALL");
  const bodytoSend = {
    company_user: action.company_user,
    company_id: action.company_id,
    user_id: action.user_id,
  };
  console.log(bodytoSend);
  return api.updateCompanyUsers(bodytoSend).then((data) => {
    console.log("TWO LETTERS", data);
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_USER_INFO,
        company_users: data,
      });
    }
  });
};

export const DeleteCompanyUsersCall = (action) =>
  api.deleteCompanyUsers(action.companyInfo).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_USER_INFO,
        company_users: data,
      });
    }
  });
