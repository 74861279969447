import * as actionTypes from "./activity.actions";
import ActivityState from "./activity.state";
import * as ActivityCalls from "./activity.calls";

const intialState = {
  ...ActivityState,
};

const reducers = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ACTIVITY:
      return {
        ...state,
        activities: action.activities,
      };
    case actionTypes.GET_ACTIVITY:
      ActivityCalls.GetActivitiesCall(action);
      return {
        ...state,
      };
    case actionTypes.ADD_ACTIVITY:
      ActivityCalls.SetActivitiesCall(action);
      return {
        ...state,
      };
    case actionTypes.UPDATE_ACTIVITY:
      ActivityCalls.UpdateActivitiesCall(action);
      return {
        ...state,
      };
    case actionTypes.DELETE_ACTIVITY:
      ActivityCalls.DeleteActivitiesCall(action);
      return {
        ...state,
      };
    default:
      return { ...state };
  }
};

export default reducers;
