import { serverURL } from "../constants";
import {
  createPutJSON,
  createPostJSON,
  createGetJSON,
  createDELETEJSON,
} from "../helper/customs";

export const addActivity = (data) => {
  return fetch(serverURL.concat("/activity"), createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateActivity = (data) => {
  return fetch(serverURL.concat("/activity"), createPutJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeActivity = (data) => {
  return fetch(serverURL.concat("/activity"), createDELETEJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getActivity = (user_id) => {
  return fetch(
    serverURL.concat("/activity?user_id=", user_id),
    createGetJSON()
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
