export const LOGIN = () => "USER_LOGIN";
export const LOGOUT = () => "USER_LOGOUT";
export const REGISTER = () => "USER_REGISTER";
export const FORGOT_PASSWORD = () => "USER_FORGOT_PASSWORD";
export const SET_PASSWORD = () => "USER_SET_PASSWORD";
export const REGISTER_ERROR = () => "USER_REGISTER_ERROR";
export const REGISTER_SUCCESS = () => "USER_REGISTER_SUCCESS";
export const LOGIN_ERROR = () => "USER_LOGIN_ERROR";
export const LOGIN_SUCCESS = () => "USER_LOGIN_SUCCESS";
export const EXTERNAL_ERROR = () => "USER_EXTERNAL_ERROR";
export const MODIFY = () => "MODIFY";
export const RESIST = () => "USER_RESIST";
export const UPDATE = () => "USER_UDPATE";
export const UPDATE_SUCCESS = () => "USER_UPDATE_SUCCESS";
