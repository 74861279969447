import { serverURL, engineURL } from "../constants";
import {
  createPutJSON,
  createPostJSON,
  createGetJSON,
  createDELETEJSON,
} from "../helper/customs";

const updateInfo = (data) => {
  return fetch(serverURL.concat("/company"), createPutJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const updateConnection = (data) => {
  return fetch(serverURL.concat("/company/db"), createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
const updateModule = (data) => {
  return fetch(serverURL.concat("/login"), createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const getCompanyUsers = (company_id) => {
  return fetch(
    serverURL.concat("/company/users?company_id=".concat(company_id)),
    createGetJSON()
  )
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const setCompanyUsers = (data) => {
  return fetch(serverURL.concat("/company/users"), createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

const deleteCompanyUsers = (data) => {
  return fetch(serverURL.concat("/company/users"), createDELETEJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
const updateCompanyUsers = (data) => {
  console.log("API", data);
  return fetch(serverURL.concat("/company/users"), createPutJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export {
  updateConnection,
  updateModule,
  updateInfo,
  getCompanyUsers,
  setCompanyUsers,
  deleteCompanyUsers,
  updateCompanyUsers,
};
