import * as actionTypes from "./sidebar.actions";
import SideBarState from "./sidebar.state";
import * as SideBarCalls from "./sidebar.calls";
// import { PURGE } from "redux-persist";

const intialState = {
  ...SideBarState,
};

const reducers = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FAVORITES_INFO:
      return { 
        ...state,
        favorites: action.favorites 
      };

    case actionTypes.SET_RECENTS_INFO:
      return { 
        ...state,
        recents: action.recents 
      };

    case actionTypes.ADD_FAVORITE:
      SideBarCalls.SetFavoritesCall(action);
      return {
        ...state,
      };

    case actionTypes.ADD_RECENT:
      SideBarCalls.SetRectentsCall(action);
      return {
        ...state,
      };

    case actionTypes.GET_FAVORITE:
      SideBarCalls.GetFavoritesCall(action);
      return {
        ...state,
      };

    case actionTypes.GET_RECENT:
      SideBarCalls.GetRecentsCall(action);
      return {
        ...state,
      };
    case actionTypes.DELETE_FAVORITE:
      SideBarCalls.DeleteFavoriteCall(action);
      return {
        ...state,
      };

    case actionTypes.DELETE_RECENT:
      SideBarCalls.DeleteRecentsCall(action);
      return {
        ...state,
      };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
