import * as actionTypes from "./company.actions";
import CompanyState from "./company.state";
import {
  UpdateInfoCall,
  UpdateConnectionCall,
  GetCompanyUsersCall,
  SetCompanyUsersCall,
  DeleteCompanyUsersCall,
  UpdateCompanyUsersCall,
} from "./company.calls";
// import { PURGE } from "redux-persist";

const intialState = {
  company: CompanyState,
  company_users: [],
};

const reducers = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INFO:
      return { ...state, company: action.company };

    case actionTypes.SET_USER_INFO:
      return { ...state, company_users: action.company_users };

    case actionTypes.UPDATE_INFO:
      UpdateInfoCall(action);
      return { ...state };

    case actionTypes.UPDATE_CONNECTION_STRING:
      UpdateConnectionCall(action);
      return { ...state };

    case actionTypes.GET_COMPANY_USER_INFO:
      GetCompanyUsersCall(action);
      return { ...state };

    case actionTypes.SET_COMPANY_USER_INFO:
      SetCompanyUsersCall(action);
      return { ...state };

    case actionTypes.DELETE_COMPANY_USER_INFO:
      DeleteCompanyUsersCall(action);
      return { ...state };

    case actionTypes.UPDATE_COMPANY_USER_INFO:
      UpdateCompanyUsersCall(action);
      return { ...state };

    default:
      return {
        ...state,
      };
  }
};

export default reducers;
