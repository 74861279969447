import * as api from "../../apis/sidebar.api";
import * as actionTypes from "./sidebar.actions";

export const SetFavoritesCall = (action) =>
  api.addUserFavorite(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_FAVORITES_INFO,
        favorites: data,
      });
    }
  });
export const SetRectentsCall = (action) =>
  api.addUserRecents(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_RECENTS_INFO,
        recents: data,
      });
    }
  });

export const GetFavoritesCall = (action) =>
  api.getUserFavorite(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_FAVORITES_INFO,
        favorites: data,
      });
    }
  });
export const GetRecentsCall = (action) =>
  api.getUserRecents(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_RECENTS_INFO,
        recents: data,
      });
    }
  });

export const DeleteRecentsCall = (action) =>
  api.removeUserRecent(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_RECENTS_INFO,
        recents: data,
      });
    }
  });

export const DeleteFavoriteCall = (action) =>
  api.removeUserFavorite(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_FAVORITES_INFO,
        favorites: data,
      });
    }
  });
