import * as api from "../../apis/activity.api";
import * as actionTypes from "./activity.actions";

export const SetActivitiesCall = (action) =>
  api.addActivity(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ACTIVITY,
        activities: data,
      });
    }
  });

export const GetActivitiesCall = (action) =>
  api.getActivity(action.user_id).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ACTIVITY,
        activities: data,
      });
    }
  });

export const UpdateActivitiesCall = (action) =>
  api.updateActivity(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ACTIVITY,
        activities: data,
      });
    }
  });

export const DeleteActivitiesCall = (action) =>
  api.removeActivity(action.Info).then((data) => {
    if (data.message) {
    } else {
      action.asyncDispatch({
        type: actionTypes.SET_ACTIVITY,
        activities: data,
      });
    }
  });
