const CompanyState = {
  company_id: 0,
  company_name: "",
  address: "",
  logo: "",
  language: "",
  connection_string: "",
  connection_host: "",
  connection_port: "",
  connection_username: "",
  connection_password: "",
  database_type: "",
};

export default CompanyState;
