export const SET_INFO = "COMPANY_SET_INFO";
export const UPDATE_INFO = "COMPANY_UPDATE_INFO";
export const UPDATE_CONNECTION_STRING = "COMPANY_UPDATE_CONNECTION_STRING";
export const UPDATE_USER_MODULES = "COMPANY_UPDATE_USER_MODULES";

export const SET_USER_INFO = "COMPANY_SET_USER_INFO";

export const GET_COMPANY_USER_INFO = "COMPANY_GET_COMPANY_USER_INFO";
export const SET_COMPANY_USER_INFO = "COMPANY_SET_COMPANY_USER_INFO";
export const DELETE_COMPANY_USER_INFO = "COMPANY_DELETE_COMPANY_USER_INFO";
export const UPDATE_COMPANY_USER_INFO = "COMPANY_UPDATE_COMPANY_USER_INFO";
