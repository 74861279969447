import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import persistConfig from "./schema";

import userReducer from "./User/user.reducers";
import companyReducer from "./Company/company.reducers";
import sidebarReducer from "./SideBar/sidebar.reducers";
import alertReducer from "./Alert/alert.reducers";
import activityReducer from "./Activity/activity.reducers";

const rootReducer = combineReducers({
  UserState: persistReducer(persistConfig.user, userReducer),
  CompanyState: persistReducer(persistConfig.company, companyReducer),
  SideBarState: persistReducer(persistConfig.sidebar, sidebarReducer),
  AlertState: persistReducer(persistConfig.alert, alertReducer),
  ActivityState: persistReducer(persistConfig.activity, activityReducer),
});

export default rootReducer;
