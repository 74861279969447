//FIXME Move assets to infotointell blob
const assetsUrl =
  "https://inglisi.sgp1.digitaloceanspaces.com/media/performit/";

const blobUrl = "https://infotointell.fra1.digitaloceanspaces.com/Performit/";

const serverURL =
  process.env.NODE_ENV === "development"
    ? "https://performit-server.azurewebsites.net/api"
    : "https://performit-server.azurewebsites.net/api";

const engineURL =
  process.env.NODE_ENV === "development"
    ? "https://perform-engine-xqnyu.ondigitalocean.app/api"
    : "https://perform-engine-xqnyu.ondigitalocean.app/api";

const ReCAPTCHA_Key = "6LfLZr4aAAAAAEANEgyau1nbXgRlrvNXKe1nGhLr";
const DELAY = 2500;

export { assetsUrl, blobUrl, serverURL, ReCAPTCHA_Key, DELAY, engineURL };
