import { serverURL } from "../constants";
import {
  createPutJSON,
  createPostJSON,
  createGetJSON,
  createDELETEJSON,
} from "../helper/customs";

export const AddAlert = (data) => {
  return fetch(serverURL.concat("/alert"), createPostJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const updateAlert = (data) => {
  return fetch(serverURL.concat("/alert"), createPutJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const removeAlert = (data) => {
  return fetch(serverURL.concat("/alert"), createDELETEJSON(data))
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getAlert = (user_id) => {
  return fetch(serverURL.concat("/alert?user_id=", user_id), createGetJSON())
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};
