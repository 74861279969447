import * as actionTypes from "./alert.actions";
import AlertState from "./alert.state";
import * as AlertCalls from "./alert.calls";

const intialState = {
  ...AlertState,
};

const reducers = (state = intialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALERT:
      return {
        ...state,
        alerts: action.alerts,
      };
    case actionTypes.GET_ALERT:
      AlertCalls.GetAlertsCall(action);
      return {
        ...state,
      };

    case actionTypes.ADD_ALERT:
      AlertCalls.SetAlertsCall(action);
      return {
        ...state,
      };
    case actionTypes.UPDATE_ALERT:
      AlertCalls.UpdateAlertsCall(action);
      return {
        ...state,
      };
    case actionTypes.DELETE_ALERT:
      AlertCalls.DeleteAlertsCall(action);
      return {
        ...state,
      };

    default:
      return { ...state };
  }
};

export default reducers;
