const Routes = {
  login: "/login",
  signup: "/signup",
  any: "*",
  bot: "/bot",
  forgot: "/forgot-password",
  admin: "/admin",
  alerts: "/alerts",
  profile: "/profile",
  activityLog: "/activity-log",
  resetPassword:"/reset-password",
  home: "/",
  kpi:"/kpi",
  skills:"/skills"
};

export default Routes;
